import React from 'react'

const SvgItSolution = props => (
  <svg id='it_solution_svg__\u89E3\u51B3\u65B9\u6848' x={0} y={0} viewBox='0 0 1001 397' xmlSpace='preserve' {...props}>
    <defs>
      <style>
        {
          '.it_solution_svg__cls-1,.it_solution_svg__cls-4{fill:#fd5c1f}.it_solution_svg__cls-2,.it_solution_svg__cls-5,.it_solution_svg__cls-8{fill:#fff}.it_solution_svg__cls-2,.it_solution_svg__cls-4,.it_solution_svg__cls-7{fill-rule:evenodd}.it_solution_svg__cls-3{font-size:14px;fill:#7b899c;font-family:&quot;Source Han Sans CN&quot;}.it_solution_svg__cls-5,.it_solution_svg__cls-7{stroke-width:1px}.it_solution_svg__cls-5{stroke:#fd5c1f;stroke-dasharray:4 2}.it_solution_svg__cls-7{fill:none;stroke:#fff}'
        }
      </style>
    </defs>
    <rect
      id='it_solution_svg__\u77E9\u5F62_82_\u62F7\u8D1D_5'
      data-name='\u77E9\u5F62 82 \u62F7\u8D1D 5'
      className='it_solution_svg__cls-1'
      x={931.5}
      width={60}
      height={60}
      rx={5}
      ry={5}
    />
    <path
      id='it_solution_svg__\u5F62\u72B6_8'
      data-name='\u5F62\u72B6 8'
      className='it_solution_svg__cls-2'
      d='M6090.74 1370v10.08h-.77V1370h.77zm29.91 11.3v-10.08h.76v10.08h-.76zm10.35 9.7h-51v-1.53h4.24a2.118 2.118 0 01-.05-.44v-24.24a1.974 1.974 0 011.97-1.97h7.13v1.54h-7.13a.435.435 0 00-.44.43v24.24a.444.444 0 00.44.44h9.29a1.611 1.611 0 01-.06-.44v-32.07a1.972 1.972 0 011.97-1.96h16.01a1.963 1.963 0 011.96 1.96v32.07a2.118 2.118 0 01-.05.44h9.69a.435.435 0 00.43-.44v-24.24a.427.427 0 00-.43-.43h-7.65v-1.54h7.65a1.974 1.974 0 011.97 1.97v24.24a1.611 1.611 0 01-.06.44h4.12v1.53zm-17.2-1.97v-32.07a.433.433 0 00-.43-.43h-16.01a.433.433 0 00-.43.43v32.07a.435.435 0 00.43.44h16.01a.435.435 0 00.43-.44zm-3.51-22.35h-10.09v-.77h10.09v.77zm0 3.7h-10.09v-.77h10.09v.77zm0 3.27h-10.09v-.77h10.09v.77zm0 0'
      transform='translate(-5143.5 -1343)'
    />
    <text
      id='it_solution_svg__\u5408\u4F5C\u4F19\u4F34_A'
      data-name='\u5408\u4F5C\u4F19\u4F34 A'
      className='it_solution_svg__cls-3'
      x={929.505}
      y={88.999}
    >
      {'\u5408\u4F5C\u4F19\u4F34 A'}
    </text>
    <rect
      id='it_solution_svg__\u77E9\u5F62_82_\u62F7\u8D1D_6'
      data-name='\u77E9\u5F62 82 \u62F7\u8D1D 6'
      className='it_solution_svg__cls-1'
      x={931.5}
      y={106}
      width={60}
      height={60}
      rx={5}
      ry={5}
    />
    <path
      id='it_solution_svg__\u5F62\u72B6_8_\u62F7\u8D1D'
      data-name='\u5F62\u72B6 8 \u62F7\u8D1D'
      className='it_solution_svg__cls-2'
      d='M6090.74 1476v10.08h-.77V1476h.77zm29.91 11.3v-10.08h.76v10.08h-.76zm10.35 9.7h-51v-1.53h4.24a2.118 2.118 0 01-.05-.44v-24.24a1.974 1.974 0 011.97-1.97h7.13v1.54h-7.13a.435.435 0 00-.44.43v24.24a.444.444 0 00.44.44h9.29a1.611 1.611 0 01-.06-.44v-32.07a1.972 1.972 0 011.97-1.96h16.01a1.963 1.963 0 011.96 1.96v32.07a2.118 2.118 0 01-.05.44h9.69a.435.435 0 00.43-.44v-24.24a.427.427 0 00-.43-.43h-7.65v-1.54h7.65a1.974 1.974 0 011.97 1.97v24.24a1.611 1.611 0 01-.06.44h4.12v1.53zm-17.2-1.97v-32.07a.433.433 0 00-.43-.43h-16.01a.433.433 0 00-.43.43v32.07a.435.435 0 00.43.44h16.01a.435.435 0 00.43-.44zm-3.51-22.35h-10.09v-.77h10.09v.77zm0 3.7h-10.09v-.77h10.09v.77zm0 3.27h-10.09v-.77h10.09v.77zm0 0'
      transform='translate(-5143.5 -1343)'
    />
    <text
      id='it_solution_svg__\u5408\u4F5C\u4F19\u4F34_B'
      data-name='\u5408\u4F5C\u4F19\u4F34 B'
      className='it_solution_svg__cls-3'
      x={929.505}
      y={195.001}
    >
      {'\u5408\u4F5C\u4F19\u4F34 B'}
    </text>
    <rect
      id='it_solution_svg__\u77E9\u5F62_82_\u62F7\u8D1D_7'
      data-name='\u77E9\u5F62 82 \u62F7\u8D1D 7'
      className='it_solution_svg__cls-1'
      x={931.5}
      y={212}
      width={60}
      height={60}
      rx={5}
      ry={5}
    />
    <path
      id='it_solution_svg__\u5F62\u72B6_8_\u62F7\u8D1D_2'
      data-name='\u5F62\u72B6 8 \u62F7\u8D1D 2'
      className='it_solution_svg__cls-2'
      d='M6090.74 1582v10.08h-.77V1582h.77zm29.91 11.3v-10.08h.76v10.08h-.76zm10.35 9.7h-51v-1.53h4.24a2.118 2.118 0 01-.05-.44v-24.24a1.974 1.974 0 011.97-1.97h7.13v1.54h-7.13a.435.435 0 00-.44.43v24.24a.444.444 0 00.44.44h9.29a1.611 1.611 0 01-.06-.44v-32.07a1.972 1.972 0 011.97-1.96h16.01a1.963 1.963 0 011.96 1.96v32.07a2.118 2.118 0 01-.05.44h9.69a.435.435 0 00.43-.44v-24.24a.427.427 0 00-.43-.43h-7.65v-1.54h7.65a1.974 1.974 0 011.97 1.97v24.24a1.611 1.611 0 01-.06.44h4.12v1.53zm-17.2-1.97v-32.07a.433.433 0 00-.43-.43h-16.01a.433.433 0 00-.43.43v32.07a.435.435 0 00.43.44h16.01a.435.435 0 00.43-.44zm-3.51-22.35h-10.09v-.77h10.09v.77zm0 3.7h-10.09v-.77h10.09v.77zm0 3.26h-10.09v-.76h10.09v.76zm0 0'
      transform='translate(-5143.5 -1343)'
    />
    <text
      id='it_solution_svg__\u5408\u4F5C\u4F19\u4F34_C'
      data-name='\u5408\u4F5C\u4F19\u4F34 C'
      className='it_solution_svg__cls-3'
      x={929.505}
      y={301}
    >
      {'\u5408\u4F5C\u4F19\u4F34 C'}
    </text>
    <path
      id='it_solution_svg__\u77E9\u5F62_82'
      data-name='\u77E9\u5F62 82'
      className='it_solution_svg__cls-4'
      d='M5940 1482.03v2h116.01v6.98l9.99-8-9.99-8v7.02H5940zm60-110v2h56.01v6.98l9.99-8-9.99-8v7.02H6000zm0 219v2h56.01v6.98l9.99-8-9.99-8v7.02H6000zm0-218.03h2v220h-2v-220z'
      transform='translate(-5143.5 -1343)'
    />
    <path
      id='it_solution_svg__\u591A\u8FB9\u5F62_2_\u62F7\u8D1D_3'
      data-name='\u591A\u8FB9\u5F62 2 \u62F7\u8D1D 3'
      className='it_solution_svg__cls-4'
      d='M5407 1491.03h40v2h-40v-2zm-9 .98l9.99-8v16zm59 0l-9.99-8v16z'
      transform='translate(-5143.5 -1343)'
    />
    <rect
      id='it_solution_svg__\u5706\u89D2\u77E9\u5F62_3_\u62F7\u8D1D_4'
      data-name='\u5706\u89D2\u77E9\u5F62 3 \u62F7\u8D1D 4'
      className='it_solution_svg__cls-5'
      x={0.5}
      y={29}
      width={240}
      height={240}
      rx={5}
      ry={5}
    />
    <text id='it_solution_svg__\u5B58\u50A8\u9635\u5217' className='it_solution_svg__cls-3' x={93.499} y={127}>
      {'\u5B58\u50A8\u9635\u5217'}
    </text>
    <text id='it_solution_svg__\u5B58\u50A8A' className='it_solution_svg__cls-3' x={41.499} y={252.998}>
      {'\u5B58\u50A8A'}
    </text>
    <text id='it_solution_svg__\u5B58\u50A8B' className='it_solution_svg__cls-3' x={160.503} y={252.998}>
      {'\u5B58\u50A8B'}
    </text>
    <path
      id='it_solution_svg__\u591A\u8FB9\u5F62_2_\u62F7\u8D1D_4'
      data-name='\u591A\u8FB9\u5F62 2 \u62F7\u8D1D 4'
      className='it_solution_svg__cls-4'
      d='M5206.41 1497.27l20-34.64 1.73 1-20 34.64zm-3.65 8.28l-1.93-12.65 13.85 8zm29.5-51.09l-11.92 4.65 13.85 8z'
      transform='translate(-5143.5 -1343)'
    />
    <path
      id='it_solution_svg__\u591A\u8FB9\u5F62_2_\u62F7\u8D1D_5'
      data-name='\u591A\u8FB9\u5F62 2 \u62F7\u8D1D 5'
      className='it_solution_svg__cls-4'
      d='M5324.49 1496.72l-19.79-34.58-1.72 1 19.8 34.58zm3.61 8.27l1.91-12.63-13.71 7.98zm-29.19-51l11.8 4.64-13.71 7.99z'
      transform='translate(-5143.5 -1343)'
    />
    <rect
      id='it_solution_svg__\u77E9\u5F62_81_\u62F7\u8D1D_4'
      data-name='\u77E9\u5F62 81 \u62F7\u8D1D 4'
      className='it_solution_svg__cls-1'
      x={92.5}
      y={44}
      width={60}
      height={60}
      rx={5}
      ry={5}
    />
    <path
      id='it_solution_svg__\u5F62\u72B6_10'
      data-name='\u5F62\u72B6 10'
      className='it_solution_svg__cls-2'
      d='M5262.28 1424.37a13.318 13.318 0 012.07-1.66c-8.95 0-14.63-3.52-14.63-5.27v-8.78c2.67 2.28 8.17 4.39 14.63 4.39 6.37 0 11.96-2.11 14.63-4.39v7.11a11.928 11.928 0 011.72 1.14v-10.89c0-3.86-7.32-7.02-16.35-7.02-8.35 0-15.15 2.55-16.18 5.97l-.17 1.05v22.83c0 3.16 6.02 5.62 13.85 6.06a10.668 10.668 0 01-1.12-1.84c-6.79-.79-11.01-3.6-11.01-5.09v-7.91c2.41 2.02 6.97 3.95 12.56 4.3zm2.07-23.61c8.95 0 14.63 3.16 14.63 5.26-.35 2.11-5.94 5.27-14.63 5.27-8.95 0-14.63-3.16-14.63-5.27s5.68-5.26 14.63-5.26zm16.35 24.32v-.18a6.455 6.455 0 10-12.91 0v.18c-2.24.52-4.3 2.19-4.3 4.65 0 2.9 3.18 5.27 6.02 5.27h10.33a5.406 5.406 0 005.16-5.27c0-2.46-2.07-4.04-4.3-4.65zm-.86 8.16h-10.33c-1.89 0-4.3-1.58-4.3-3.51s1.98-3.07 3.87-3.07c.26 0 .52.09.78.09a5.62 5.62 0 01-.35-1.76 4.735 4.735 0 119.47 0 4.624 4.624 0 01-.35 1.84 2.59 2.59 0 01.78-.08c1.89 0 3.87 1.14 3.87 3.07a3.468 3.468 0 01-3.44 3.42z'
      transform='translate(-5143.5 -1343)'
    />
    <rect
      id='it_solution_svg__\u77E9\u5F62_81_\u62F7\u8D1D_5'
      data-name='\u77E9\u5F62 81 \u62F7\u8D1D 5'
      className='it_solution_svg__cls-1'
      x={30.5}
      y={167}
      width={60}
      height={60}
      rx={5}
      ry={5}
    />
    <path
      id='it_solution_svg__\u5F62\u72B6_10_\u62F7\u8D1D'
      data-name='\u5F62\u72B6 10 \u62F7\u8D1D'
      className='it_solution_svg__cls-2'
      d='M5200.28 1547.38a12.8 12.8 0 012.07-1.67c-8.95 0-14.63-3.52-14.63-5.27v-8.78c2.67 2.28 8.17 4.39 14.63 4.39 6.37 0 11.96-2.11 14.63-4.39v7.11a11.928 11.928 0 011.72 1.14v-10.89c0-3.86-7.32-7.02-16.35-7.02-8.35 0-15.15 2.55-16.18 5.97l-.17 1.05v22.83c0 3.16 6.02 5.62 13.85 6.06a10.668 10.668 0 01-1.12-1.84c-6.79-.79-11.01-3.6-11.01-5.1v-7.9c2.41 2.02 6.97 3.95 12.56 4.31zm2.07-23.62c8.95 0 14.63 3.16 14.63 5.26-.35 2.11-5.94 5.27-14.63 5.27-8.95 0-14.63-3.16-14.63-5.27s5.68-5.26 14.63-5.26zm16.35 24.32v-.18a6.455 6.455 0 10-12.91 0v.18c-2.24.52-4.3 2.19-4.3 4.65 0 2.9 3.18 5.27 6.02 5.27h10.33a5.406 5.406 0 005.16-5.27c0-2.46-2.07-4.04-4.3-4.65zm-.86 8.16h-10.33c-1.89 0-4.3-1.58-4.3-3.51s1.98-3.07 3.87-3.07c.26 0 .52.09.78.09a5.62 5.62 0 01-.35-1.76 4.735 4.735 0 119.47 0 4.624 4.624 0 01-.35 1.84 2.59 2.59 0 01.78-.08c1.89 0 3.87 1.14 3.87 3.07a3.468 3.468 0 01-3.44 3.42z'
      transform='translate(-5143.5 -1343)'
    />
    <rect
      id='it_solution_svg__\u77E9\u5F62_81_\u62F7\u8D1D_6'
      data-name='\u77E9\u5F62 81 \u62F7\u8D1D 6'
      className='it_solution_svg__cls-1'
      x={150.5}
      y={167}
      width={60}
      height={60}
      rx={5}
      ry={5}
    />
    <path
      id='it_solution_svg__\u5F62\u72B6_10_\u62F7\u8D1D_2'
      data-name='\u5F62\u72B6 10 \u62F7\u8D1D 2'
      className='it_solution_svg__cls-2'
      d='M5320.28 1547.38a12.8 12.8 0 012.07-1.67c-8.95 0-14.63-3.52-14.63-5.27v-8.78c2.67 2.28 8.17 4.39 14.63 4.39 6.37 0 11.96-2.11 14.63-4.39v7.11a11.928 11.928 0 011.72 1.14v-10.89c0-3.86-7.32-7.02-16.35-7.02-8.35 0-15.15 2.55-16.18 5.97l-.17 1.05v22.83c0 3.16 6.02 5.62 13.85 6.06a10.668 10.668 0 01-1.12-1.84c-6.79-.79-11.01-3.6-11.01-5.1v-7.9c2.41 2.02 6.97 3.95 12.56 4.31zm2.07-23.62c8.95 0 14.63 3.16 14.63 5.26-.35 2.11-5.94 5.27-14.63 5.27-8.95 0-14.63-3.16-14.63-5.27s5.68-5.26 14.63-5.26zm16.35 24.32v-.18a6.455 6.455 0 10-12.91 0v.18c-2.24.52-4.3 2.19-4.3 4.65 0 2.9 3.18 5.27 6.02 5.27h10.33a5.406 5.406 0 005.16-5.27c0-2.46-2.07-4.04-4.3-4.65zm-.86 8.16h-10.33c-1.89 0-4.3-1.58-4.3-3.51s1.98-3.07 3.87-3.07c.26 0 .52.09.78.09a5.62 5.62 0 01-.35-1.76 4.735 4.735 0 119.47 0 4.624 4.624 0 01-.35 1.84 2.59 2.59 0 01.78-.08c1.89 0 3.87 1.14 3.87 3.07a3.468 3.468 0 01-3.44 3.42z'
      transform='translate(-5143.5 -1343)'
    />
    <rect
      id='it_solution_svg__\u5706\u89D2\u77E9\u5F62_3_\u62F7\u8D1D_3'
      data-name='\u5706\u89D2\u77E9\u5F62 3 \u62F7\u8D1D 3'
      className='it_solution_svg__cls-5'
      x={320.5}
      y={89}
      width={460}
      height={120}
      rx={5}
      ry={5}
    />
    <text
      id='it_solution_svg__\u603B\u90E8\u6570\u636E\u5B58\u50A8\u4E2D\u5FC3'
      className='it_solution_svg__cls-3'
      x={382.504}
      y={194}
    >
      {'\u603B\u90E8\u6570\u636E\u5B58\u50A8\u4E2D\u5FC3'}
    </text>
    <rect
      id='it_solution_svg__\u77E9\u5F62_81'
      data-name='\u77E9\u5F62 81'
      className='it_solution_svg__cls-1'
      x={401.5}
      y={109}
      width={60}
      height={60}
      rx={5}
      ry={5}
    />
    <rect
      id='it_solution_svg__\u77E9\u5F62_81_\u62F7\u8D1D'
      data-name='\u77E9\u5F62 81 \u62F7\u8D1D'
      className='it_solution_svg__cls-1'
      x={616.5}
      y={109}
      width={60}
      height={60}
      rx={5}
      ry={5}
    />
    <path
      id='it_solution_svg__\u5F62\u72B6_4'
      data-name='\u5F62\u72B6 4'
      className='it_solution_svg__cls-2'
      d='M5803.61 1471.31l2.16-1.38-.8-2-2.49.58a6.4 6.4 0 00-1.28-1.32l.56-2.53-1.96-.83-1.34 2.19a5.914 5.914 0 00-1.83-.01l-1.33-2.19-1.97.81.54 2.52a6.261 6.261 0 00-1.3 1.32l-2.46-.6-.82 2 2.13 1.39a6.172 6.172 0 00-.01 1.87l-2.15 1.35.8 2 2.48-.55a6.206 6.206 0 001.29 1.32l-.58 2.51 1.96.83 1.38-2.18a6.235 6.235 0 001.83 0l1.36 2.2 1.96-.82-.57-2.53a6.073 6.073 0 001.28-1.3l2.5.57.82-1.99-2.17-1.38a6.745 6.745 0 00.01-1.85zm-3.36 2.07a2.945 2.945 0 01-3.87 1.62 3.034 3.034 0 01-1.59-3.94 2.937 2.937 0 013.87-1.62 3.034 3.034 0 011.59 3.94zm4.59 18.67l1.82-1.16-.68-1.67-2.07.49a4.9 4.9 0 00-1.08-1.1l.48-2.12-1.64-.69-1.12 1.83a4.625 4.625 0 00-1.53-.01l-1.11-1.83-1.64.68.45 2.1a4.948 4.948 0 00-1.09 1.1l-2.06-.5-.68 1.67 1.78 1.16a5.439 5.439 0 00-.01 1.56l-1.79 1.13.67 1.67 2.07-.46a5.117 5.117 0 001.08 1.1l-.49 2.1 1.64.69 1.15-1.82a4.843 4.843 0 001.52 0l1.14 1.84 1.64-.69-.48-2.11a5.032 5.032 0 001.07-1.09l2.09.48.68-1.66-1.81-1.15a4.97 4.97 0 000-1.54zm-2.79 1.72a2.462 2.462 0 01-3.24 1.35 2.539 2.539 0 01-1.33-3.29 2.47 2.47 0 013.24-1.35 2.539 2.539 0 011.33 3.29zm-7.18-6.29v-2.82l-3.3-.55a8.37 8.37 0 00-.91-2.23l1.93-2.79-1.97-1.99-2.7 1.98a7.573 7.573 0 00-2.21-.93l-.57-3.35h-2.78l-.53 3.34a7.439 7.439 0 00-2.21.92l-2.7-1.96-1.97 1.99 1.9 2.76a8.454 8.454 0 00-.92 2.26l-3.26.55v2.82l3.26.59a8.389 8.389 0 00.92 2.25l-1.93 2.74 1.97 2 2.72-1.94a7.853 7.853 0 002.21.93l.54 3.33h2.78l.58-3.34a7.694 7.694 0 002.2-.94l2.74 1.96 1.96-2-1.95-2.76a8.088 8.088 0 00.9-2.23zm-11.1 2.55a3.936 3.936 0 113.87-3.94 3.908 3.908 0 01-3.87 3.94z'
      transform='translate(-5143.5 -1343)'
    />
    <text
      id='it_solution_svg__Raysync\u9AD8\u901F\u5F15\u64CE\u4F20\u8F93'
      className='it_solution_svg__cls-3'
      x={582.499}
      y={194}
    >
      {'Raysync\u9AD8\u901F\u5F15\u64CE\u4F20\u8F93'}
    </text>
    <g id='it_solution_svg__\u7EC4_9' data-name='\u7EC4 9'>
      <path
        id='it_solution_svg__\u77E9\u5F62_706'
        data-name='\u77E9\u5F62 706'
        fill='none'
        stroke='#fff'
        strokeWidth={1}
        d='M421.5 121h20v36h-20z'
      />
      <path
        id='it_solution_svg__\u77E9\u5F62_706_\u62F7\u8D1D'
        data-name='\u77E9\u5F62 706 \u62F7\u8D1D'
        className='it_solution_svg__cls-7'
        d='M5587 1466h9v32h-9'
        transform='translate(-5143.5 -1343)'
      />
      <path
        id='it_solution_svg__\u77E9\u5F62_706_\u62F7\u8D1D_2'
        data-name='\u77E9\u5F62 706 \u62F7\u8D1D 2'
        className='it_solution_svg__cls-7'
        d='M5563 1467h-8v30h8'
        transform='translate(-5143.5 -1343)'
      />
      <path
        id='it_solution_svg__\u77E9\u5F62_707'
        data-name='\u77E9\u5F62 707'
        className='it_solution_svg__cls-8'
        d='M425.5 129h12v2h-12z'
      />
      <path
        id='it_solution_svg__\u77E9\u5F62_707_\u62F7\u8D1D'
        data-name='\u77E9\u5F62 707 \u62F7\u8D1D'
        className='it_solution_svg__cls-8'
        d='M425.5 136h12v2h-12z'
      />
      <path
        id='it_solution_svg__\u77E9\u5F62_707_\u62F7\u8D1D_2'
        data-name='\u77E9\u5F62 707 \u62F7\u8D1D 2'
        className='it_solution_svg__cls-8'
        d='M425.5 143h12v2h-12z'
      />
    </g>
  </svg>
)

export default SvgItSolution
