import React from 'react'

const SvgInternetFeature2 = props => (
  <svg id='internet_feature2_svg__\u56FE\u5C42_1' x={0} y={0} viewBox='0 0 50 50' xmlSpace='preserve' {...props}>
    <style>{'.internet_feature2_svg__st0{fill:#334966}.internet_feature2_svg__st1{fill:#fd5c1f}'}</style>
    <g id='internet_feature2_svg__\u6570\u636E\u5BB9\u707E-48x48_1_'>
      <path
        id='internet_feature2_svg__Combined-Shape_27_'
        className='internet_feature2_svg__st0'
        d='M12.23 41h1.7v4h22.13v-6h1.7v8H12.23v-6zm0-2h1.7-1.7zM37.77 9h-1.7V5H13.94v6h-1.7V3h25.53v6zm-1.71 2h1.7-1.7z'
      />
      <path
        id='internet_feature2_svg__Rectangle-24-Copy_1_'
        className='internet_feature2_svg__st0'
        d='M4.58 11H21.6v28H4.58V11zm1.7 2v24H19.9V13H6.28z'
      />
      <path
        id='internet_feature2_svg__Rectangle-24-Copy-2_1_'
        className='internet_feature2_svg__st0'
        d='M28.4 11h17.02v28H28.4V11zm1.71 2v24h13.62V13H30.11z'
      />
      <path
        id='internet_feature2_svg__Rectangle-38_1_'
        className='internet_feature2_svg__st1'
        d='M7.98 15h10.21v8H7.98z'
      />
      <path
        id='internet_feature2_svg__Rectangle-38-Copy_1_'
        className='internet_feature2_svg__st1'
        d='M31.81 15h10.21v8H31.81z'
      />
      <path
        id='internet_feature2_svg__Rectangle-38-Copy-2_1_'
        className='internet_feature2_svg__st1'
        d='M7.98 25h10.21v2H7.98z'
      />
      <path
        id='internet_feature2_svg__Rectangle-38-Copy-5_1_'
        className='internet_feature2_svg__st1'
        d='M31.81 25h10.21v2H31.81z'
      />
      <path
        id='internet_feature2_svg__Rectangle-38-Copy-3_1_'
        className='internet_feature2_svg__st1'
        d='M7.98 29h10.21v2H7.98z'
      />
      <path
        id='internet_feature2_svg__Rectangle-38-Copy-6_1_'
        className='internet_feature2_svg__st1'
        d='M31.81 29h10.21v2H31.81z'
      />
      <path
        id='internet_feature2_svg__Rectangle-38-Copy-4_1_'
        className='internet_feature2_svg__st1'
        d='M7.98 33h10.21v2H7.98z'
      />
      <path
        id='internet_feature2_svg__Rectangle-38-Copy-7_1_'
        className='internet_feature2_svg__st1'
        d='M31.81 33h10.21v2H31.81z'
      />
    </g>
  </svg>
)

export default SvgInternetFeature2
