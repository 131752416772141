import React from 'react'

const SvgItSolutionEn = props => (
  <svg
    id='it_solution_en_svg__\u89E3\u51B3\u65B9\u6848en'
    x={0}
    y={0}
    viewBox='0 0 991.5 301'
    xmlSpace='preserve'
    {...props}
  >
    <style>
      {
        '.it_solution_en_svg__st0{fill:#fd5c1f}.it_solution_en_svg__st1{fill-rule:evenodd;clip-rule:evenodd;fill:#fff}.it_solution_en_svg__st2{fill:#7b899c}.it_solution_en_svg__st3{font-family:&apos;OpenSans&apos;}.it_solution_en_svg__st4{font-size:14px}.it_solution_en_svg__st5{fill-rule:evenodd;clip-rule:evenodd;fill:#fd5c1f}.it_solution_en_svg__st6{fill:#fff;stroke:#fd5c1f;stroke-dasharray:4,2}.it_solution_en_svg__st8{fill:none;stroke:#fff}.it_solution_en_svg__st9{fill:#fff}'
      }
    </style>
    <path
      id='it_solution_en_svg__\u77E9\u5F62_82_\u62F7\u8D1D_5'
      className='it_solution_en_svg__st0'
      d='M936.5 0h50c2.76 0 5 2.24 5 5v50c0 2.76-2.24 5-5 5h-50c-2.76 0-5-2.24-5-5V5c0-2.76 2.24-5 5-5z'
    />
    <path
      id='it_solution_en_svg__\u5F62\u72B6_8'
      className='it_solution_en_svg__st1'
      d='M947.24 27v10.08h-.77V27h.77zm29.91 11.3V28.22h.76V38.3h-.76zM987.5 48h-51v-1.53h4.24c-.03-.14-.05-.29-.05-.44V21.79c0-1.09.88-1.97 1.97-1.97h7.13v1.54h-7.13c-.24 0-.44.19-.44.43v24.24c0 .24.2.44.44.44h9.29c-.04-.14-.06-.29-.06-.44V13.96c.01-1.08.89-1.96 1.97-1.96h16.01c1.08 0 1.96.88 1.96 1.96v32.07c0 .15-.02.3-.05.44h9.69c.24 0 .43-.2.43-.44V21.79c0-.24-.19-.43-.42-.43h-7.66v-1.54h7.65c1.09 0 1.97.88 1.97 1.97v24.24c0 .15-.02.3-.05.44h4.11V48zm-17.2-1.97V13.96c0-.24-.19-.43-.43-.43h-16.01c-.24 0-.43.19-.43.43v32.07c0 .24.19.44.43.44h16.01c.24 0 .43-.2.43-.44zm-3.51-22.35H956.7v-.77h10.09v.77zm0 3.7H956.7v-.77h10.09v.77zm0 3.27H956.7v-.77h10.09v.77z'
    />
    <text
      id='it_solution_en_svg__Partner_A'
      transform='translate(929.498 89)'
      className='it_solution_en_svg__st2 it_solution_en_svg__st3 it_solution_en_svg__st4'
    >
      {'Partner A'}
    </text>
    <path
      id='it_solution_en_svg__\u77E9\u5F62_82_\u62F7\u8D1D_6'
      className='it_solution_en_svg__st0'
      d='M936.5 106h50c2.76 0 5 2.24 5 5v50c0 2.76-2.24 5-5 5h-50c-2.76 0-5-2.24-5-5v-50c0-2.76 2.24-5 5-5z'
    />
    <path
      id='it_solution_en_svg__\u5F62\u72B6_8_\u62F7\u8D1D'
      className='it_solution_en_svg__st1'
      d='M947.24 133v10.08h-.77V133h.77zm29.91 11.3v-10.08h.76v10.08h-.76zm10.35 9.7h-51v-1.53h4.24c-.03-.14-.05-.29-.05-.44v-24.24c0-1.09.88-1.97 1.97-1.97h7.13v1.54h-7.13c-.24 0-.44.19-.44.43v24.24c0 .24.2.44.44.44h9.29c-.04-.14-.06-.29-.06-.44v-32.07c.01-1.08.89-1.96 1.97-1.96h16.01c1.08 0 1.96.88 1.96 1.96v32.07c0 .15-.02.3-.05.44h9.69c.24 0 .43-.2.43-.44v-24.24c0-.24-.19-.43-.42-.43h-7.66v-1.54h7.65c1.09 0 1.97.88 1.97 1.97v24.24c0 .15-.02.3-.05.44h4.11V154zm-17.2-1.97v-32.07c0-.24-.19-.43-.43-.43h-16.01c-.24 0-.43.19-.43.43v32.07c0 .24.19.44.43.44h16.01c.24 0 .43-.2.43-.44zm-3.51-22.35H956.7v-.77h10.09v.77zm0 3.7H956.7v-.77h10.09v.77zm0 3.27H956.7v-.77h10.09v.77z'
    />
    <text
      id='it_solution_en_svg__Partner_B'
      transform='translate(929.498 194.999)'
      className='it_solution_en_svg__st2 it_solution_en_svg__st3 it_solution_en_svg__st4'
    >
      {'Partner B'}
    </text>
    <path
      id='it_solution_en_svg__\u77E9\u5F62_82_\u62F7\u8D1D_7'
      className='it_solution_en_svg__st0'
      d='M936.5 212h50c2.76 0 5 2.24 5 5v50c0 2.76-2.24 5-5 5h-50c-2.76 0-5-2.24-5-5v-50c0-2.76 2.24-5 5-5z'
    />
    <path
      id='it_solution_en_svg__\u5F62\u72B6_8_\u62F7\u8D1D_2'
      className='it_solution_en_svg__st1'
      d='M947.24 239v10.08h-.77V239h.77zm29.91 11.3v-10.08h.76v10.08h-.76zm10.35 9.7h-51v-1.53h4.24c-.03-.14-.05-.29-.05-.44v-24.24c0-1.09.88-1.97 1.97-1.97h7.13v1.54h-7.13c-.24 0-.44.19-.44.43v24.24c0 .24.2.44.44.44h9.29c-.04-.14-.06-.29-.06-.44v-32.07c.01-1.08.89-1.96 1.97-1.96h16.01c1.08 0 1.96.88 1.96 1.96v32.07c0 .15-.02.3-.05.44h9.69c.24 0 .43-.2.43-.44v-24.24c0-.24-.19-.43-.42-.43h-7.66v-1.54h7.65c1.09 0 1.97.88 1.97 1.97v24.24c0 .15-.02.3-.05.44h4.11V260zm-17.2-1.97v-32.07c0-.24-.19-.43-.43-.43h-16.01c-.24 0-.43.19-.43.43v32.07c0 .24.19.44.43.44h16.01c.24 0 .43-.2.43-.44zm-3.51-22.35H956.7v-.77h10.09v.77zm0 3.7H956.7v-.77h10.09v.77zm0 3.27H956.7v-.77h10.09v.77z'
    />
    <text
      id='it_solution_en_svg__Partner_C'
      transform='translate(929.498 301.001)'
      className='it_solution_en_svg__st2 it_solution_en_svg__st3 it_solution_en_svg__st4'
    >
      {'Partner C'}
    </text>
    <path
      id='it_solution_en_svg__\u77E9\u5F62_82'
      className='it_solution_en_svg__st5'
      d='M796.5 139.03v2h116.01v6.98l9.99-8-9.99-8v7.02H796.5zm60-110v2h56.01v6.98l9.99-8-9.99-8v7.02H856.5zm0 219v2h56.01v6.98l9.99-8-9.99-8v7.02H856.5zm0-218.03h2v220h-2V30z'
    />
    <path
      id='it_solution_en_svg__\u591A\u8FB9\u5F62_2_\u62F7\u8D1D_3'
      className='it_solution_en_svg__st5'
      d='M263.5 148.03h40v2h-40v-2zm-9 .98l9.99-8v16l-9.99-8zm59 0l-9.99-8v16l9.99-8z'
    />
    <path
      id='it_solution_en_svg__\u5706\u89D2\u77E9\u5F62_3_\u62F7\u8D1D_4'
      className='it_solution_en_svg__st6'
      d='M5.5 29h230c2.76 0 5 2.24 5 5v230c0 2.76-2.24 5-5 5H5.5c-2.76 0-5-2.24-5-5V34c0-2.76 2.24-5 5-5z'
    />
    <text
      transform='translate(98.501 128)'
      className='it_solution_en_svg__st2 it_solution_en_svg__st3 it_solution_en_svg__st4'
    >
      {'Storage'}
    </text>
    <text
      transform='translate(98.501 148.006)'
      className='it_solution_en_svg__st2 it_solution_en_svg__st3 it_solution_en_svg__st4'
    >
      {'Arrays'}
    </text>
    <text
      id='it_solution_en_svg__Storage_A'
      transform='translate(27.502 252.999)'
      className='it_solution_en_svg__st2 it_solution_en_svg__st3 it_solution_en_svg__st4'
    >
      {'Storage A'}
    </text>
    <text
      id='it_solution_en_svg__Storage_B'
      transform='translate(148.496 252.999)'
      className='it_solution_en_svg__st2 it_solution_en_svg__st3 it_solution_en_svg__st4'
    >
      {'Storage B'}
    </text>
    <path
      id='it_solution_en_svg__\u591A\u8FB9\u5F62_2_\u62F7\u8D1D_4'
      className='it_solution_en_svg__st5'
      d='M62.91 154.27l20-34.64 1.73 1-20 34.64-1.73-1zm-3.65 8.28l-1.93-12.65 13.85 8-11.92 4.65zm29.5-51.09l-11.92 4.65 13.85 8-1.93-12.65z'
    />
    <path
      id='it_solution_en_svg__\u591A\u8FB9\u5F62_2_\u62F7\u8D1D_5'
      className='it_solution_en_svg__st5'
      d='M180.99 153.72l-19.79-34.58-1.72 1 19.8 34.58 1.71-1zm3.61 8.27l1.91-12.63-13.71 7.98 11.8 4.65zm-29.19-51l11.8 4.64-13.71 7.99 1.91-12.63z'
    />
    <path
      id='it_solution_en_svg__\u77E9\u5F62_81_\u62F7\u8D1D_4'
      className='it_solution_en_svg__st0'
      d='M97.5 44h50c2.76 0 5 2.24 5 5v50c0 2.76-2.24 5-5 5h-50c-2.76 0-5-2.24-5-5V49c0-2.76 2.24-5 5-5z'
    />
    <path
      id='it_solution_en_svg__\u5F62\u72B6_10'
      className='it_solution_en_svg__st1'
      d='M118.78 81.38c.63-.63 1.32-1.19 2.07-1.67-8.95 0-14.63-3.52-14.63-5.27v-8.78c2.67 2.28 8.18 4.39 14.63 4.39 6.37 0 11.96-2.11 14.63-4.39v7.11c.61.33 1.18.71 1.72 1.14V63.02c0-3.86-7.32-7.02-16.35-7.02-8.35 0-15.15 2.55-16.18 5.97l-.17 1.05v22.83c0 3.16 6.02 5.62 13.85 6.06-.43-.57-.81-1.19-1.12-1.84-6.79-.79-11.01-3.6-11.01-5.09v-7.91c2.41 2.02 6.97 3.95 12.56 4.31zm2.07-23.62c8.95 0 14.63 3.16 14.63 5.26-.35 2.11-5.94 5.27-14.63 5.27-8.95 0-14.63-3.16-14.63-5.27s5.68-5.26 14.63-5.26zm16.35 24.32v-.18c0-3.57-2.89-6.46-6.46-6.46s-6.46 2.89-6.46 6.46v.18c-2.24.52-4.3 2.19-4.3 4.65 0 2.9 3.18 5.27 6.02 5.27h10.33a5.406 5.406 0 005.16-5.27c.01-2.46-2.06-4.04-4.29-4.65zm-.86 8.16h-10.33c-1.89 0-4.3-1.58-4.3-3.51s1.98-3.07 3.87-3.07c.26 0 .52.09.78.09-.21-.56-.33-1.16-.35-1.76a4.735 4.735 0 119.47 0c.01.63-.11 1.26-.35 1.84.25-.07.52-.09.78-.08 1.89 0 3.87 1.14 3.87 3.07a3.468 3.468 0 01-3.44 3.42z'
    />
    <path
      id='it_solution_en_svg__\u77E9\u5F62_81_\u62F7\u8D1D_5'
      className='it_solution_en_svg__st0'
      d='M35.5 167h50c2.76 0 5 2.24 5 5v50c0 2.76-2.24 5-5 5h-50c-2.76 0-5-2.24-5-5v-50c0-2.76 2.24-5 5-5z'
    />
    <path
      id='it_solution_en_svg__\u5F62\u72B6_10_\u62F7\u8D1D'
      className='it_solution_en_svg__st1'
      d='M56.78 204.38c.63-.63 1.32-1.19 2.07-1.67-8.95 0-14.63-3.52-14.63-5.27v-8.78c2.67 2.28 8.17 4.39 14.63 4.39 6.37 0 11.96-2.11 14.63-4.39v7.11c.61.33 1.18.71 1.72 1.14v-10.89c0-3.86-7.32-7.02-16.35-7.02-8.35 0-15.15 2.55-16.18 5.97l-.17 1.05v22.83c0 3.16 6.02 5.62 13.85 6.06-.43-.57-.81-1.19-1.12-1.84-6.79-.79-11.01-3.6-11.01-5.09v-7.91c2.41 2.02 6.97 3.95 12.56 4.31zm2.07-23.62c8.95 0 14.63 3.16 14.63 5.26-.35 2.11-5.94 5.27-14.63 5.27-8.95 0-14.63-3.16-14.63-5.27s5.68-5.26 14.63-5.26zm16.35 24.32v-.18c0-3.57-2.89-6.46-6.46-6.46s-6.46 2.89-6.46 6.46v.18c-2.24.52-4.3 2.19-4.3 4.65 0 2.9 3.18 5.27 6.02 5.27h10.33a5.406 5.406 0 005.16-5.27c.01-2.46-2.06-4.04-4.29-4.65zm-.86 8.16H64.01c-1.89 0-4.3-1.58-4.3-3.51s1.98-3.07 3.87-3.07c.26 0 .52.09.78.09-.21-.56-.33-1.16-.35-1.76a4.735 4.735 0 119.47 0c.01.63-.11 1.26-.35 1.84.25-.07.52-.09.78-.08 1.89 0 3.87 1.14 3.87 3.07a3.468 3.468 0 01-3.44 3.42z'
    />
    <path
      id='it_solution_en_svg__\u77E9\u5F62_81_\u62F7\u8D1D_6'
      className='it_solution_en_svg__st0'
      d='M155.5 167h50c2.76 0 5 2.24 5 5v50c0 2.76-2.24 5-5 5h-50c-2.76 0-5-2.24-5-5v-50c0-2.76 2.24-5 5-5z'
    />
    <path
      id='it_solution_en_svg__\u5F62\u72B6_10_\u62F7\u8D1D_2'
      className='it_solution_en_svg__st1'
      d='M176.78 204.38c.63-.63 1.32-1.19 2.07-1.67-8.95 0-14.63-3.52-14.63-5.27v-8.78c2.67 2.28 8.18 4.39 14.63 4.39 6.37 0 11.96-2.11 14.63-4.39v7.11c.61.33 1.18.71 1.72 1.14v-10.89c0-3.86-7.32-7.02-16.35-7.02-8.35 0-15.15 2.55-16.18 5.97l-.17 1.05v22.83c0 3.16 6.02 5.62 13.85 6.06-.43-.57-.81-1.19-1.12-1.84-6.79-.79-11.01-3.6-11.01-5.09v-7.91c2.41 2.02 6.97 3.95 12.56 4.31zm2.07-23.62c8.95 0 14.63 3.16 14.63 5.26-.35 2.11-5.94 5.27-14.63 5.27-8.95 0-14.63-3.16-14.63-5.27s5.68-5.26 14.63-5.26zm16.35 24.32v-.18c0-3.57-2.89-6.46-6.46-6.46s-6.46 2.89-6.46 6.46v.18c-2.24.52-4.3 2.19-4.3 4.65 0 2.9 3.18 5.27 6.02 5.27h10.33a5.406 5.406 0 005.16-5.27c.01-2.46-2.06-4.04-4.29-4.65zm-.86 8.16h-10.33c-1.89 0-4.3-1.58-4.3-3.51s1.98-3.07 3.87-3.07c.26 0 .52.09.78.09-.21-.56-.33-1.16-.35-1.76a4.735 4.735 0 119.47 0c.01.63-.11 1.26-.35 1.84.25-.07.52-.09.78-.08 1.89 0 3.87 1.14 3.87 3.07a3.468 3.468 0 01-3.44 3.42z'
    />
    <path
      id='it_solution_en_svg__\u5706\u89D2\u77E9\u5F62_3_\u62F7\u8D1D_3'
      className='it_solution_en_svg__st6'
      d='M325.5 89h450c2.76 0 5 2.24 5 5v110c0 2.76-2.24 5-5 5h-450c-2.76 0-5-2.24-5-5V94c0-2.76 2.24-5 5-5z'
    />
    <text id='it_solution_en_svg__Gene_Research_Data_Center' transform='translate(363.42 184.816)'>
      <tspan x={0} y={0} className='it_solution_en_svg__st2 it_solution_en_svg__st3 it_solution_en_svg__st4'>
        {'Headquarters Data '}
      </tspan>
      <tspan x={15.44} y={16.8} className='it_solution_en_svg__st2 it_solution_en_svg__st3 it_solution_en_svg__st4'>
        {'Storage Center'}
      </tspan>
    </text>
    <path
      id='it_solution_en_svg__\u77E9\u5F62_81'
      className='it_solution_en_svg__st0'
      d='M406.5 109h50c2.76 0 5 2.24 5 5v50c0 2.76-2.24 5-5 5h-50c-2.76 0-5-2.24-5-5v-50c0-2.76 2.24-5 5-5z'
    />
    <path
      id='it_solution_en_svg__\u77E9\u5F62_81_\u62F7\u8D1D'
      className='it_solution_en_svg__st0'
      d='M621.5 109h50c2.76 0 5 2.24 5 5v50c0 2.76-2.24 5-5 5h-50c-2.76 0-5-2.24-5-5v-50c0-2.76 2.24-5 5-5z'
    />
    <path
      id='it_solution_en_svg__\u5F62\u72B6_4'
      className='it_solution_en_svg__st1'
      d='M660.11 128.31l2.16-1.38-.8-2-2.49.58c-.36-.5-.79-.95-1.28-1.32l.56-2.53-1.96-.83-1.34 2.19c-.61-.1-1.22-.1-1.83-.01l-1.33-2.19-1.97.81.54 2.52c-.5.37-.93.82-1.3 1.32l-2.46-.59-.82 1.99 2.13 1.39c-.1.62-.1 1.25-.01 1.87l-2.15 1.35.8 2 2.48-.55c.36.5.8.95 1.29 1.32l-.58 2.51 1.96.83 1.38-2.18c.61.09 1.22.09 1.83 0l1.36 2.2 1.96-.82-.57-2.53c.49-.37.92-.8 1.28-1.3l2.5.57.82-1.99-2.17-1.38c.09-.61.09-1.24.01-1.85zm-3.36 2.07a2.952 2.952 0 01-3.83 1.64c-.01-.01-.03-.01-.04-.02a3.038 3.038 0 01-1.59-3.94c.6-1.51 2.31-2.24 3.82-1.64.02.01.03.01.05.02 1.51.66 2.22 2.41 1.59 3.94zm4.59 18.67l1.82-1.16-.68-1.67-2.07.49a4.9 4.9 0 00-1.08-1.1l.48-2.12-1.64-.69-1.12 1.83c-.51-.09-1.02-.09-1.53-.01l-1.11-1.83-1.64.68.45 2.1c-.42.31-.79.68-1.09 1.1l-2.05-.5-.69 1.67 1.78 1.16c-.08.52-.08 1.04-.01 1.56l-1.79 1.13.67 1.67 2.07-.46c.3.42.67.79 1.08 1.1l-.49 2.1 1.64.69 1.15-1.82c.5.08 1.02.08 1.52 0l1.14 1.84 1.64-.69-.48-2.11c.41-.31.77-.67 1.07-1.09l2.09.48.68-1.66-1.81-1.15c.08-.51.08-1.03 0-1.54zm-2.79 1.72a2.47 2.47 0 01-3.2 1.37c-.01-.01-.02-.01-.04-.02a2.543 2.543 0 01-1.33-3.29 2.475 2.475 0 013.22-1.36c.01 0 .02.01.02.01 1.26.55 1.85 2.01 1.33 3.29zm-7.18-6.29v-2.82l-3.3-.55a8.31 8.31 0 00-.91-2.23l1.93-2.79-1.97-1.99-2.7 1.98a7.42 7.42 0 00-2.21-.93l-.57-3.35h-2.78l-.53 3.34c-.78.19-1.53.5-2.21.92l-2.7-1.96-1.97 1.99 1.9 2.76c-.41.7-.72 1.47-.92 2.26l-3.26.55v2.82l3.26.59c.2.79.51 1.55.92 2.25l-1.93 2.74 1.97 2 2.72-1.94c.68.42 1.43.73 2.21.93l.54 3.33h2.78l.58-3.34c.78-.2 1.52-.51 2.2-.94l2.74 1.96 1.96-2-1.95-2.76c.41-.69.71-1.45.9-2.23l3.3-.59zm-11.1 2.55c-2.17.04-3.96-1.7-4-3.87s1.7-3.96 3.87-4 3.96 1.7 4 3.87v.06a3.9 3.9 0 01-3.87 3.94z'
    />
    <text
      id='it_solution_en_svg__Raysync_Engine'
      transform='translate(596.5 194.001)'
      className='it_solution_en_svg__st2 it_solution_en_svg__st3 it_solution_en_svg__st4'
    >
      {'Raysync Engine'}
    </text>
    <g id='it_solution_en_svg__\u7EC4_9'>
      <path id='it_solution_en_svg__\u77E9\u5F62_706' className='it_solution_en_svg__st8' d='M421 121.01h20v36h-20z' />
      <path
        id='it_solution_en_svg__\u77E9\u5F62_706_\u62F7\u8D1D'
        className='it_solution_en_svg__st8'
        d='M443 123.01h9v32h-9'
      />
      <path
        id='it_solution_en_svg__\u77E9\u5F62_706_\u62F7\u8D1D_2'
        className='it_solution_en_svg__st8'
        d='M419 124.01h-8v30h8'
      />
      <path id='it_solution_en_svg__\u77E9\u5F62_707' className='it_solution_en_svg__st9' d='M425 129.01h12v2h-12z' />
      <path
        id='it_solution_en_svg__\u77E9\u5F62_707_\u62F7\u8D1D'
        className='it_solution_en_svg__st9'
        d='M425 136.01h12v2h-12z'
      />
      <path
        id='it_solution_en_svg__\u77E9\u5F62_707_\u62F7\u8D1D_2'
        className='it_solution_en_svg__st9'
        d='M425 143.01h12v2h-12z'
      />
    </g>
  </svg>
)

export default SvgItSolutionEn
