import React from 'react'

const SvgInternetFeature4 = props => (
  <svg id='internet_feature4_svg__\u56FE\u5C42_1' x={0} y={0} viewBox='0 0 50 50' xmlSpace='preserve' {...props}>
    <style>{'.internet_feature4_svg__st0{fill:#334966}'}</style>
    <path
      className='internet_feature4_svg__st0'
      d='M3.98 31.51l7.58 1.26-6.13 6.13C-1.25 29.53-.38 16.43 8.02 8.02 16.43-.39 29.53-1.25 38.9 5.43l-1.44 1.44C28.9.96 17.07 1.82 9.44 9.44c-5.97 5.98-7.79 14.53-5.46 22.07zM46.02 18.49l-7.58-1.26 6.13-6.13c6.68 9.37 5.81 22.47-2.59 30.88-8.41 8.41-21.51 9.27-30.88 2.59l1.44-1.44c8.57 5.91 20.4 5.05 28.03-2.57 5.96-5.98 7.78-14.53 5.45-22.07z'
    />
    <path
      d='M19 33c-3.87 0-7-3.13-7-7s3.13-7 7-7c.1 0 .19 0 .29.01a7.007 7.007 0 018.7-4.73c3.01.89 5.06 3.67 5.01 6.8 3.27-.55 6.36 1.65 6.91 4.92A5.994 5.994 0 0133 32.91V33H19z'
      fill='#fd5c1f'
    />
  </svg>
)

export default SvgInternetFeature4
